<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 profile-screen delegates-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("profile.delegate.permission") }}
        </div>
        <template v-slot:right>
          <button v-if="delegation" @click="updateDelegate()">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <section v-if="delegation">
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="permanently"
                    type="checkbox"
                    v-model="permanently"
                  />
                  <label for="permanently"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("profile.delegate.permanently") }}
              </dd>
            </dl>
          </div>
        </li>
      </ul>
      <ul v-if="!permanently" class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFrom) }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button class="clebex-item-range-cta">
                  {{ displayDate(selectedDateTo) }}
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li v-if="calendar" class="clebex-item-section-item overflow-hidden">
          <div class="clebex-item-content-wrapper">
            <select-date-range
              :selectDateFn="selectDate"
              :show-date-picker="true"
              :disablePreviousDays="true"
              :dates-to-watch="[selectedDateFrom, selectedDateTo]"
              :selected-dates="[selectedDateFrom, selectedDateTo]"
            />
          </div>
        </li>
      </ul>
    </section>
  </mobile-screen>
  <router-view />
</template>

<script>
import SelectDateRange from "@/components/global/SelectDateRange";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";

export default {
  name: "ProfileMyDelegatesUpdatePermission",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange
  },
  mounted() {
    if (this.delegation) {
      this.permanently = this.delegation.datetime_to ? false : true;
      this.setDates(this.delegation);
    }
  },
  data() {
    return {
      permanently: false,
      selectedDateFrom: false,
      selectedDateTo: false,
      calendar: true
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat"]),
    ...mapState("delegates", ["myDelegates"]),
    delegation() {
      if (this.myDelegates && this.myDelegates.length) {
        const delegation = this.myDelegates.find(
          el => el.id == this.$route.params.delegationId
        );
        if (delegation) {
          return delegation;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("delegates", ["updateDelegation"]),
    updateDelegate() {
      const postObject = {
        id: this.delegation.id,
        permission_id: this.delegation.permission.id,
        delegate_id: this.delegation.delegate.id,
        datetime_from: this.permanently
          ? null
          : this.setDate(this.selectedDateFrom),
        datetime_to: this.permanently ? null : this.setDate(this.selectedDateTo)
      };
      this.updateDelegation(postObject);
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
      } else {
        this.selectedDateFrom = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
      } else {
        this.selectedDateTo = null;
      }
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(
        DateTime.fromFormat(date, "yyyy-dd-MM").toFormat("yyyy-MM-dd"),
        this.globalDateFormat
      );
    },
    setDate(date) {
      if (!date) {
        return null;
      }
      return DateTime.fromFormat(date, "yyyy-dd-MM").toFormat("yyyy-MM-dd");
    },
    setDates(delegation) {
      this.calendar = false;
      const selectedDateFrom = delegation.datetime_from;
      const selectedDateTo = delegation.datetime_to;
      if (selectedDateFrom) {
        this.selectedDateFrom = DateTime.fromISO(selectedDateFrom).toFormat(
          "yyyy-dd-MM"
        );
      } else {
        this.selectedDateFrom = null;
      }
      if (selectedDateTo) {
        this.selectedDateTo = DateTime.fromISO(selectedDateTo).toFormat(
          "yyyy-dd-MM"
        );
      } else {
        this.selectedDateTo = null;
      }
      this.$nextTick(() => {
        this.calendar = true;
      });
    }
  },
  watch: {
    delegation: {
      deep: true,
      handler(val) {
        if (val) {
          this.permanently = val.datetime_to ? false : true;
          this.setDates(val);
        }
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
